export function ucWords(str) {
  return str.toString().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
}

/**
 * Format a number with commas
 * @param x
 * @returns {string}
 * @example
 * formatNumberWithCommas(1000) // 1,000
 * formatNumberWithCommas(1000000) // 1,000,000
 * formatNumberWithCommas(1000000000) // 1,000,000,000
 */
export function formatNumberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Returns the correct Arabic word for "year" based on the input number.
 *
 * @param {number} value - The number of years.
 * @param {Function} t - The translation function.
 * @returns {string} - The correct Arabic word for year:
 *   - "سنة" for 1 year.
 *   - "سنوات" for 3 to 10 years.
 *   - "سنة" for 11 and above.
 */
export function getArabicYearWord(value, t) {
  if (value === 2) {
    return t('سنتين', {
      ns: "web_common"
    }); // Use "سنتين" for
  } else if (value >= 3 && value <= 10) {
    return t('سنوات', {
      ns: "web_common"
    }); // Plural: 3 to 10 years
  } else {
    return t('سنة', {
      ns: "web_common"
    }); // Singular used for 11 years and above
  }
}

/**
 * Remove commas from a string
 * @param str
 * @returns {string}
 * @example
 * removeCommas('1,000') // '1000'
 * removeCommas('1,000,000') // '1000000'
 * removeCommas('1,000,000,000') // '1000000000'
 */
export function removeCommas(str) {
  return str.toString().replace(/,/g, '');
}

/**
 * Check if a value is a string
 * @param value
 * @returns {boolean}
 * @example
 * isString('hello') // true
 * isString(123) // false
 */
export function isString(value) {
  return typeof value === 'string';
}

/**
 * Check if a string contains any character except a comma
 * @param value
 * @returns {boolean}
 * @example
 * containsStringNotComma('hello,world') // true
 * containsStringNotComma(',,,') // false
 */
export function containsStringNotComma(value) {
  return /[^,]/.test(value);
}
export function nl2br(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

/**
 * Convert a string to a list of items
 * @param input
 */
export function stringToListItems(input) {
  return input.split('\n').map(function (line, index) {
    return "<li key=\"list-item-".concat(index, "\">").concat(line, "</li>");
  });
}